<template>
    <div class="p-grid page">
        <div class="p-col-12">
            <div class="p-col-12">
                <Breadcrumb :model="items"/>
            </div>
            <div class="p-d-flex p-col-12">
                <InputText placeholder="活动名称"/>
                <Button icon="pi pi-search" label="查询" class="p-button-info p-ml-2"/>
                <Calendar style="width: 22.5%" class="p-ml-2" v-model="date" placeholder="活动时间"/>
            </div>
            <div class="p-mt-1 p-p-2">
                <div class="p-mt-1">
                    <div class="p-p-2 p-mt-2" style="border: 1px solid #999">
                        <div class="p-grid">
                            <div class="p-col-10">
                                <text style="font-size: 18px; color: #90A4AE; font-weight: bold"> 布草洗涤优化高效解决方案</text>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid p-mt-1">
                                    <div class="p-col-2">时间：2021-04-31</div>
                                    <div class="p-col-3">地点： 星河湾酒店</div>
                                </div>
                            </div>
                            <div style="border: 1px solid #999;background-color: #95999c" class="p-col-12"></div>
                            <div class="p-col-12 p-grid">
                                <div class="p-p-3">
                                    已有127家企业确认参加活动：星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS...
                                </div>
                                <div class="p-col-12 p-grid  p-jc-end">
                                    <div class="p-ml-5">
                                        <Button class="p-mr-2 p-button-text" label="全部" @click="gotoViews()"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-mt-1 p-p-2">
                <div class="p-mt-1">
                    <div class="p-p-2 p-mt-2" style="border: 1px solid #999">
                        <div class="p-grid">
                            <div class="p-col-10">
                                <text style="font-size: 18px; color: #90A4AE; font-weight: bold"> 布草洗涤优化高效解决方案</text>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid p-mt-1">
                                    <div class="p-col-2">时间：2021-04-31</div>
                                    <div class="p-col-3">地点： 星河湾酒店</div>
                                </div>
                            </div>
                            <div style="border: 1px solid #999;background-color: #95999c" class="p-col-12"></div>
                            <div class="p-col-12 p-grid">
                                <div class="p-p-3">
                                    已有127家企业确认参加活动：星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS...
                                </div>
                                <div class="p-col-12 p-grid  p-jc-end">
                                    <div class="p-ml-5">
                                        <Button class="p-mr-2 p-button-text" label="全部" @click="gotoViews()"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-mt-1 p-p-2">
                <div class="p-mt-1">
                    <div class="p-p-2 p-mt-2" style="border: 1px solid #999">
                        <div class="p-grid">
                            <div class="p-col-10">
                                <text style="font-size: 18px; color: #95999c; font-weight: bold"> 布草洗涤优化高效解决方案</text>
                            </div>
                            <div class="p-col-12">
                                <div class="p-grid p-mt-1">
                                    <div class="p-col-2">时间：2021-04-31</div>
                                    <div class="p-col-3">地点： 星河湾酒店</div>
                                </div>
                            </div>
                            <div style="border: 1px solid #999;background-color: #95999c" class="p-col-12"></div>
                            <div class="p-col-12 p-grid">
                                <div class="p-p-3">
                                    已有127家企业确认参加活动：星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS 蓝金信息科技 新触点网络信息 星河湾酒店 西安零工保FSS...
                                </div>
                                <div class="p-col-12 p-grid  p-jc-end">
                                    <div class="p-ml-5">
                                        <Button class="p-mr-2 p-button-text " label="全部" @click="gotoViews()"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                date: null,
                items: [
                    {label: '企业会员管理', to: '/DemandSeminar'},
                    {label: '需求研讨会议（活动组织）', to: '/DemandSeminar'},
                    {label: '活动确认', to: '/activityConfirmation'}
                ]
            }
        }
    }
</script>

<style scoped>

</style>